import { useEffect, useState } from "react";
import {
  CustFormAddchipsWithInput,
  CustFormImage,
  CustFormImageView,
  CustFormMultiselect,
  CustFormMultiselectWithSearch,
  CustFormSimgleselectWithSearch,
  CustFormSkingleselect,
  CustFormSkingleselectChips,
  CustFormText,
  CustFormTextView,
} from "./FormElements";
const emailRegex =
  /^(?!\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?!\.)$/;

const CustForm = ({
  inputs = [],
  setdata,
  data,
  type,
  is_saving,
  actions,
  close,
  save,
  mark,
  markandemail,
  deleteentry,
}) => {
  const [error, seterror] = useState({});
  const [valueerror, setvalueerror] = useState(false);
  const [reuired_keys, setReuired_keys] = useState([]);

  useEffect(() => {
    setReuired_keys(
      inputs.filter((input) => input.required).map((input) => input.Statekey)
    );
    return () => {};
  }, [inputs]);
  useEffect(() => {
    if (reuired_keys.length > 0) {
      setvalueerror(reuired_keys.filter((key) => !data[key]).length > 0);
    }

    return () => {
      setvalueerror(false);
    };
  }, [data, reuired_keys]);

  const error_handler = (Statekey, value) => {
    if (reuired_keys.includes(Statekey) && !value) {
      seterror((prev) => ({
        ...prev,
        [Statekey]: true,
      }));
    } else {
      if (Statekey === "email" && !emailRegex.test(value)) {
        seterror((prev) => ({
          ...prev,
          [Statekey]: true,
        }));
      } else {
        seterror((prev) => {
          let a = prev;
          delete a[Statekey];
          return {
            ...prev,
          };
        });
      }
    }
  };

  // console.log("errors", error, valueerror);

  return (
    <div className="cust_form add_form">
      <div className="cust_form_main">
        <div className="form_type">{type}</div>
        <div className="form_group">
          {inputs.map(
            (input, index) =>
              ({
                text: (
                  <CustFormText
                    key={index}
                    {...{
                      ...input,
                      inputname: input.Statekey,
                      value: data[input.Statekey],
                      setvalue: ({ target: { value } }) => {
                        setdata({ ...data, [input.Statekey]: value });
                        error_handler(input.Statekey, value);
                      },
                      error: error[input.Statekey],
                    }}
                  />
                ),
                viewtext: (
                  <CustFormTextView
                    key={index}
                    {...{
                      ...input,
                      inputname: input.Statekey,
                      value: data[input.Statekey],
                      setvalue: ({ target: { value } }) => {
                        setdata({ ...data, [input.Statekey]: value });
                        error_handler(input.Statekey, value);
                      },
                      error: error[input.Statekey],
                    }}
                  />
                ),
                image: (
                  <CustFormImage
                    key={index}
                    {...{
                      ...input,
                      inputname: input.Statekey,
                      value: data[input.Statekey],
                      setvalue: (value) => {
                        setdata({ ...data, [input.Statekey]: value });
                        error_handler(input.Statekey, value.name);
                      },
                      error: error[input.Statekey],
                    }}
                  />
                ),
                imageview: (
                  <CustFormImageView
                    key={index}
                    {...{
                      ...input,
                      inputname: input.Statekey,
                      value: data[input.Statekey],
                      setvalue: (value) => {
                        setdata({ ...data, [input.Statekey]: value });
                        error_handler(input.Statekey, value.name);
                      },
                      error: error[input.Statekey],
                    }}
                  />
                ),
                multiselect: (
                  <CustFormMultiselect
                    {...{
                      ...input,
                      inputname: input.Statekey,
                      value: data[input.Statekey],
                      setvalue: (value) => {
                        let prev = data[input.Statekey];
                        console.log(prev);

                        let new_array = prev.includes(value)
                          ? prev.filter((el1) => el1 != value)
                          : [...prev, value];
                        setdata({
                          ...data,
                          [input.Statekey]: new_array,
                        });
                        error_handler(input.Statekey, new_array.join(""));
                      },

                      error: error[input.Statekey],
                      options: input.options,
                      selected: data[input.Statekey],
                    }}
                  />
                ),
                multiselectwithsearch: (
                  <CustFormMultiselectWithSearch
                    {...{
                      ...input,
                      inputname: input.Statekey,
                      value: data[input.Statekey],
                      setvalue: (value) => {
                        let prev = data[input.Statekey];
                        console.log(prev);

                        let new_array = prev.includes(value)
                          ? prev.filter((el1) => el1 != value)
                          : [...prev, value];
                        setdata({
                          ...data,
                          [input.Statekey]: new_array,
                        });
                        error_handler(input.Statekey, new_array.join(""));
                      },

                      error: error[input.Statekey],
                      options: input.options,
                      selected: data[input.Statekey],
                    }}
                  />
                ),
                addchipswithinput: (
                  <CustFormAddchipsWithInput
                    {...{
                      ...input,
                      inputname: input.Statekey,
                      value: data[input.Statekey],
                      setvalue: (value, type) => {
                        let prev = data[input.Statekey];
                        console.log(prev);
                        if (type == "add" && !prev.includes(value)) {
                          let new_array = [...prev, value];
                          setdata({
                            ...data,
                            [input.Statekey]: new_array,
                          });
                          error_handler(input.Statekey, new_array.join(""));
                        }
                        if (type == "remove") {
                          let new_array = prev.filter((el1) => el1 != value);
                          setdata({
                            ...data,
                            [input.Statekey]: new_array,
                          });
                          error_handler(input.Statekey, new_array.join(""));
                        }
                      },

                      error: error[input.Statekey],
                      options: input.options,
                      selected: data[input.Statekey],
                    }}
                  />
                ),
                singleselectwithsearch: (
                  <CustFormSimgleselectWithSearch
                    {...{
                      ...input,
                      inputname: input.Statekey,
                      value: data[input.Statekey],
                      add: input.add,
                      setvalue: (value) => {
                        let prev = data[input.Statekey];
                        // console.log(prev);

                        // let new_array = prev.includes(value)
                        //   ? prev.filter((el1) => el1 != value)
                        //   : [...prev, value];
                        setdata({
                          ...data,
                          [input.Statekey]: prev == value ? "" : value,
                        });
                        error_handler(
                          input.Statekey,
                          prev == value ? "" : value
                        );
                      },

                      error: error[input.Statekey],
                      options: input.options,
                      selected: data[input.Statekey],
                    }}
                  />
                ),
                singleselect: (
                  <CustFormSkingleselect
                    {...{
                      ...input,
                      inputname: input.Statekey,
                      value: data[input.Statekey],
                      setvalue: (value) => {
                        let prev = data[input.Statekey];
                        // console.log(prev);

                        // let new_array = prev.includes(value)
                        //   ? prev.filter((el1) => el1 != value)
                        //   : [...prev, value];
                        setdata({
                          ...data,
                          [input.Statekey]: prev == value ? "" : value,
                        });
                        error_handler(
                          input.Statekey,
                          prev == value ? "" : value
                        );
                      },

                      error: error[input.Statekey],
                      options: input.options,
                      selected: data[input.Statekey],
                    }}
                  />
                ),
                singleselectchips: (
                  <CustFormSkingleselectChips
                    {...{
                      ...input,
                      inputname: input.Statekey,
                      value: data[input.Statekey],
                      setvalue: (value) => {
                        let prev = data[input.Statekey];
                        // console.log(prev);

                        // let new_array = prev.includes(value)
                        //   ? prev.filter((el1) => el1 != value)
                        //   : [...prev, value];
                        setdata({
                          ...data,
                          [input.Statekey]: prev == value ? "" : value,
                        });
                        error_handler(
                          input.Statekey,
                          prev == value ? "" : value
                        );
                      },

                      error: error[input.Statekey],
                      options: input.options,
                      selected: data[input.Statekey],
                    }}
                  />
                ),
              }?.[input?.type || ""] || "")
          )}
        </div>
        <div className="btn_group">
          {is_saving ? (
            <div className="btn save" active={true}>
              Submitting...
            </div>
          ) : (
            <>
              {actions.map(
                ({ type, btntype }, index) =>
                  ({
                    save: (
                      <div
                        className={`btn ${btntype}`}
                        active={String(
                          Object.values(error).length == 0 && !valueerror
                        )}
                        onClick={() =>
                          Object.values(error).length == 0 && !valueerror
                            ? save()
                            : ""
                        }
                      >
                        Submit
                      </div>
                    ),
                    close: (
                      <div className="btn cancel" onClick={() => close()}>
                        Cancel
                      </div>
                    ),
                    mark: (
                      <div
                        active={"true"}
                        className={`btn ${btntype}`}
                        onClick={() => mark()}
                      >
                        Mark
                      </div>
                    ),
                    marked: (
                      <div active={"true"} className={`btn ${btntype}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          xlink="http://www.w3.org/1999/xlink"
                          width="20"
                          height="20"
                          x="0"
                          y="0"
                          viewBox="0 0 24 24"
                          class=""
                        >
                          <g>
                            <path
                              d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                              fill="#000000"
                              opacity="1"
                              data-original="#000000"
                              class=""
                            ></path>
                          </g>
                        </svg>
                        Marked
                      </div>
                    ),
                    markandemail: (
                      <div
                        className={`btn ${btntype}`}
                        active={"true"}
                        onClick={() => markandemail()}
                      >
                        Mark and Email
                      </div>
                    ),
                    email: (
                      <div
                        className={`btn ${btntype}`}
                        active={"true"}
                        onClick={() => markandemail()}
                      >
                        Mark and Email
                      </div>
                    ),
                    markedandemailed: (
                      <div className={`btn ${btntype}`} active={"true"}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          xlink="http://www.w3.org/1999/xlink"
                          width="20"
                          height="20"
                          x="0"
                          y="0"
                          viewBox="0 0 24 24"
                          class=""
                        >
                          <g>
                            <path
                              d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                              fill="#000000"
                              opacity="1"
                              data-original="#000000"
                              class=""
                            ></path>
                          </g>
                        </svg>
                        Marked and Emailed
                      </div>
                    ),
                    deleteentry: (
                      <div
                        className={`btn ${btntype}`}
                        active={"true"}
                        onClick={() => deleteentry()}
                      >
                        Delete Entry
                      </div>
                    ),
                  }[type || ""])
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default CustForm;
